'use strict';

var processInclude = require('base/util');

processInclude(require('./accordion'));
processInclude(require('./miniCart'));
processInclude(require('./swatchSelector'));
processInclude(require('./spinner'));
processInclude(require('./search'));
processInclude(require('./dropdownOver'));
processInclude(require('./anchorLink'));
processInclude(require('./quantitySelector'));
processInclude(require('./pickupInStore'));
processInclude(require('./shareLink'));