'use strict';

const { alertMessage } = require('../common/common');

const SELECTORS = {
    element: '.js-share-link'
};


/**
 * Share Link component
 */
class ShareLink {
    constructor(element) {
        this.element = element;
        this.initEvents();
    }

    initEvents() {
        this.element.on('click', (e) => {
            e.preventDefault();
            const linkCopiedText = this.element.data('link-copied');
            const title = this.element.data('link-title');
            const description = this.element.data('link-description');
            const urlToShare = $(SELECTORS.selectedVariantUrl).val() || window.location.href;
            const shareData = {
                title: title ? title.content : '',
                description: description ? description.content : '',
                url: urlToShare
            };

            if (navigator.share && (window.isMobile || window.isTablet)) {
                navigator.share(shareData);
            } else if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(shareData.url).then(() => {
                    alertMessage(linkCopiedText, 'cc-alert--success');
                }).catch(() => {
                    console.error('Error copying to clipboard');
                });
            }
        });
    }
}

module.exports = {
    initializeClass: function () {
        $(SELECTORS.element).each(function () {
            new ShareLink($(this));
        });
    }
}